import React from 'react'

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

// import AdSense from 'react-adsense';

import Header from '../common/template/header';
import Footer from '../common/template/footer';
import Routes from './routes';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyC_glp9ikN5tK_i9B32aB3_e5nKUmjnOP4",
    authDomain: "webapps-248cf.firebaseapp.com",
    projectId: "webapps-248cf",
    storageBucket: "webapps-248cf.appspot.com",
    messagingSenderId: "976814006097",
    appId: "1:976814006097:web:47f4a1ca9a30478e86bd70",
    measurementId: "G-P01JRL6HLD"
};

const App = (props) => {

    // Initialize Firebase
    const app = initializeApp(firebaseConfig);
    if (app.name && typeof window !== 'undefined') {
        getAnalytics(app);
    }

    return (
        <React.Fragment>
            <Header />
            <div>
                <div className="container mt-main">
                    <main className="flex-shrink-0">
                        {/* <AdSense.Google
                            client='ca-pub-2605486701851317'
                            slot='1555768400'
                            style={{ display: 'block' }}
                            format='auto'
                            responsive='true'
                            layoutKey='-gw-1+2a-9x+5c'
                        /> */}
                        <Routes {...props} />
                    </main>
                </div>
            </div>
            <Footer />
        </React.Fragment>
    )
}

export default App