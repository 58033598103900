import React from 'react'

const header = (props) => {
  return (
    <header>
      <nav className="navbar navbar-expand-md navbar-light fixed-top">
        <div className="container-fluid">
          <a className="navbar-brand logo-text" href="/">
            <img src="/logo32.png" alt="ongooglemaps.com" className="logo" />Google Maps Generator</a>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          {/* <div className="collapse navbar-collapse justify-content-end" id="navbarCollapse">
            <ul className="navbar-nav mb-2 mb-md-0">
              <li className="nav-item">
                <a className="nav-link" href="/" title="Embed Google Maps">Embed Google Maps</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/openstreetmap" title="Embed OpenStreetMap">Embed OpenStreetMap</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/blog" >Blog</a>
              </li>
            </ul>
          </div> */}
        </div>
      </nav>
    </header >
  )
}

export default header