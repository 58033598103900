import React from 'react'
import { Routes, Route } from 'react-router'
import { LANGUAGES } from '../_i18n/languages';
import loadable from '@loadable/component'
import {
    useLocation,
    useParams
} from "react-router-dom";

const About = loadable(() => import('../about/About'))
const Terms = loadable(() => import('../terms/Terms'))
const Privacy = loadable(() => import('../privacy/Privacy'))
const NotFound = loadable(() => import('../notFound/NotFound'))
const GMaps = loadable(() => import('../embed/GMaps'))


const MultiLanguageRoute = ({ Component, location, custom }) => {
    const params = useParams();
    //console.log('params->'+JSON.stringify(params));
    //console.log(location)

    let hasLang = params.lang
    hasLang = hasLang ? hasLang : 'en'
    // let pathParam1 = location.pathname.split('/')[1]
    // let pathParam2 = props.location.pathname.split('/')[2]
    //let pathParam3 = location.pathname.split('/')[3]
    const is404Page = location.pathname.split('/')[3]
    //const is404Page = (pathParam3 && pathParam1 !== 'countdown-timer') ? true : pathParam3 && pathParam3.indexOf('timer') === -1
    //const isBasePathWithoutLang = props.location.pathname === "/"
    const currentLanguage = LANGUAGES[hasLang]

    //if(isBasePathWithoutLang)  return <Redirect to={`/${defaultLanguage}`} />
    //if(!hasLang && !is404Page) return <Redirect to={`/${defaultLanguage}`} />
    if (!currentLanguage || is404Page) return <NotFound location={location} staticContext={location.staticContext} />

    return <Component location={location} custom={custom} />
}

const Router = (props) => {
    const location = useLocation();
    location.staticContext = props.staticContext;
    //console.log(location); 
    return (
        <React.Fragment>
            <Routes>
                <Route path='/about' element={<About />} />
                <Route path='/terms' element={<Terms />} />
                <Route path='/privacy' element={<Privacy />} />
                <Route path='/:lang?' element={<MultiLanguageRoute Component={GMaps} location={location} />} />
                <Route path='/create-google-map/:lang?' element={<MultiLanguageRoute Component={GMaps} location={location} custom={{
                    'title': 'embedGMaps.create-google-map.seo.title',
                    'description': 'embedGMaps.create-google-map.seo.description',
                    'h1': 'embedGMaps.create-google-map.h1',
                    'desc1': 'embedGMaps.create-google-map.desc.1'
                }} />} />
                <Route path='/google-directions-maps/:lang?' element={<MultiLanguageRoute Component={GMaps} location={location} custom={{
                    'title': 'embedGMaps.google-directions-maps.seo.title',
                    'description': 'embedGMaps.google-directions-maps.seo.description',
                    'h1': 'embedGMaps.google-directions-maps.h1',
                    'desc1': 'embedGMaps.google-directions-maps.desc.1'
                }} />} />
                <Route path='/my-maps/:lang?' element={<MultiLanguageRoute Component={GMaps} location={location} custom={{
                    'title': 'embedGMaps.my-maps.seo.title',
                    'description': 'embedGMaps.my-maps.seo.description',
                    'h1': 'embedGMaps.my-maps.h1',
                    'desc1': 'embedGMaps.my-maps.desc.1'
                }} />} />
                <Route path='/maps-directions/:lang?' element={<MultiLanguageRoute Component={GMaps} location={location} custom={{
                    'title': 'embedGMaps.maps-directions.seo.title',
                    'description': 'embedGMaps.maps-directions.seo.description',
                    'h1': 'embedGMaps.maps-directions.h1',
                    'desc1': 'embedGMaps.maps-directions.desc.1'
                }} />} />
                <Route path='/google-maps-generator/:lang?' element={<MultiLanguageRoute Component={GMaps} location={location} custom={{
                    'title': 'embedGMaps.google-maps-generator.seo.title',
                    'description': 'embedGMaps.google-maps-generator.seo.description',
                    'h1': 'embedGMaps.google-maps-generator.h1',
                    'desc1': 'embedGMaps.google-maps-generator.desc.1'
                }} />} />
                <Route path='/embed-map/:lang?' element={<MultiLanguageRoute Component={GMaps} location={location} custom={{
                    'title': 'embedGMaps.embed-map.seo.title',
                    'description': 'embedGMaps.embed-map.seo.description',
                    'h1': 'embedGMaps.embed-map.h1',
                    'desc1': 'embedGMaps.embed-map.desc.1'
                }} />} />
                <Route path='*' element={<NotFound {...props} />} />
            </Routes>
        </React.Fragment>
    )
}

export default Router